import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text } from 'wix-ui-tpa/Text';
import styles from './Details.st.css';
import { AlignmentOptions, SectionTypes } from '../../types';
import Section from '../Section';
import { DetailsSectionViewModel } from '../../../../service-page-view-model/servicePageViewModel';

interface DetailsProps
  extends InjectedExperimentsProps,
    InjectedTranslateProps {
  detailsBoxAlignment: AlignmentOptions;
  detailsTextAlignment: AlignmentOptions;
  detailsButtonAlignment: AlignmentOptions;
  detailsDaysVisibility: boolean;
  detailsDurationVisibility: boolean;
  detailsPriceVisibility: boolean;
  detailsLocationVisibility: boolean;
  viewModel: DetailsSectionViewModel;
}

export default translate()(
  withExperiments<DetailsProps>(
    ({
      t,
      experiments,
      detailsBoxAlignment,
      detailsTextAlignment,
      detailsButtonAlignment,
      detailsDaysVisibility,
      detailsDurationVisibility,
      detailsPriceVisibility,
      detailsLocationVisibility,
      viewModel,
      ...rest
    }) => {
      const buttonAlignment =
        detailsBoxAlignment === AlignmentOptions.STRETCH
          ? detailsButtonAlignment
          : detailsBoxAlignment;
      return (
        <Section
          sectionType={SectionTypes.DETAILS}
          withBookButton
          buttonAlignment={buttonAlignment}
        >
          <div
            {...styles(
              'root',
              {
                boxAlignment: detailsBoxAlignment,
                textAlignment:
                  detailsBoxAlignment !== AlignmentOptions.STRETCH
                    ? detailsBoxAlignment
                    : detailsTextAlignment,
              },
              rest,
            )}
            data-hook="details-wrapper"
          >
            {detailsDaysVisibility && (
              <Text className={styles.box} data-hook="details-days-offered">
                {viewModel.daysOffered}
              </Text>
            )}
            {detailsDurationVisibility && (
              <Text className={styles.box} data-hook="details-duration">
                {viewModel.duration}
              </Text>
            )}
            {detailsPriceVisibility && (
              <Text className={styles.box} data-hook="details-price">
                {viewModel.price}
              </Text>
            )}
            {detailsLocationVisibility && (
              <Text className={styles.box} data-hook="details-location">
                {viewModel.location}
              </Text>
            )}
          </div>
        </Section>
      );
    },
  ),
);
