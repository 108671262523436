import React, { createRef } from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './Header.st.css';
import { MediaImage } from 'wix-ui-core/media-image';

interface ControllerProps {}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

// export default translate()(
//   withExperiments<WidgetProps>(({ t, experiments, ...rest }) => {
//     return (
//       <div {...styles('root', {}, rest)} data-hook="title-and-tagline-wrapper">
//         <div className={styles.media}>
//           <MediaImage
//             errorMediaPlatformItem={{
//               height: 400,
//               uri: '34a06998b3dd45e718c519ae53919e5a.jpg',
//               width: 400,
//             }}
//             height={350}
//             mediaPlatformItem={{
//               height: 350,
//               uri: '506418dbb019414f951a61670f3255a8.jpg',
//               width: 1000,
//             }}
//             width={1000}
//           />
//         </div>
//         <Text
//           data-hook="title-and-tagline-title"
//           className={styles.title}
//           typography={TYPOGRAPHY.largeTitle}
//           tagName="h2"
//         >
//           it is Title my dudes
//         </Text>
//       </div>
//     );
//   }),
// );

export class Header extends React.Component {
  private readonly headerRef;

  constructor(props) {
    super(props);
    this.headerRef = createRef();
  }

  render() {
    return (
      <div
        {...styles('root', {}, this.props)}
        data-hook="title-and-tagline-wrapper"
        ref={this.headerRef}
      >
        {this.headerRef.current?.getClientRects().length ? (
          <div className={styles.media}>
            <MediaImage
              mediaPlatformItem={{
                height: this.headerRef.current.getClientRects()[0].height,
                uri: '506418dbb019414f951a61670f3255a8.jpg',
                width: this.headerRef.current.getClientRects()[0].width,
              }}
              height={this.headerRef.current.getClientRects()[0].height}
              width={this.headerRef.current.getClientRects()[0].width}
            />
          </div>
        ) : null}
        <Text
          data-hook="title-and-tagline-title"
          className={styles.title}
          typography={TYPOGRAPHY.largeTitle}
          tagName="h2"
        >
          Italian Cooking Class
        </Text>
      </div>
    );
  }
}
