import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import styles from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';
import Details from './Details';
import Contact from './Contact';
import { AlignmentOptions, ISection, SectionTypes } from '../types';
import { Header } from './Header/Header';
import { componentSettings } from '../componentSettings';
import { ISettingsValues } from '@wix/tpa-settings';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';
import EmptyState from './EmptyState';

export interface ControllerProps {
  settings: ISettingsValues<typeof componentSettings>;
  config;
  viewModel?: ServicePageViewModel;
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

const getSectionByType = (
  type: SectionTypes,
  settings: ISettingsValues<typeof componentSettings>,
  config,
  viewModel: ServicePageViewModel,
) => {
  switch (type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          serviceTaglineVisibility={settings.serviceTaglineVisibility}
          titleAndTaglineAlignment={settings.titleAndTaglineAlignment}
          serviceTitleVisibility={settings.serviceTitleVisibility}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      return (
        <div style={{ padding: '32px', fontSize: '16px' }}>scheduling</div>
      );
    case SectionTypes.POLICY:
      return <div style={{ padding: '32px', fontSize: '16px' }}>policy</div>;
    case SectionTypes.DETAILS:
      return (
        <Details
          detailsDaysVisibility={settings.detailsDaysVisibility}
          detailsDurationVisibility={settings.detailsDurationVisibility}
          detailsLocationVisibility={settings.detailsLocationVisibility}
          detailsPriceVisibility={settings.detailsPriceVisibility}
          detailsTextAlignment={settings.detailsTextAlignment}
          detailsBoxAlignment={settings.detailsBoxAlignment}
          detailsButtonAlignment={settings.detailsButtonAlignment}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <div style={{ padding: '32px', fontSize: '16px' }}>description</div>
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          contactDetailsTitleText={settings.contactDetailsTitleText}
          config={config}
          businessAddressVisibility={settings.businessAddressVisibility}
          businessEmailVisibility={settings.businessEmailVisibility}
          businessPhoneNumberVisibility={settings.businessPhoneNumberVisibility}
        />
      );
    default:
      return null;
  }
};

export default translate()(
  withExperiments<WidgetProps>(
    ({ t, experiments, settings, config, viewModel }) => {
      return viewModel ? (
        <div {...styles('root', {})} data-hook="booking-service-page-wrapper">
          {settings.headerVisibility ? <Header /> : null}
          <div className={styles.dynamicWrapper}>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${settings.contentWidth}%` }}
            >
              {settings.sections.map((section) =>
                section.visible
                  ? getSectionByType(section.type, settings, config, viewModel)
                  : null,
              )}
            </div>
            {settings.sidebarVisibility ? (
              <div
                className={styles.dynamicItem}
                style={{ flexBasis: `${100 - settings.contentWidth}%` }}
              >
                Hello I'm The Sidebar
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <EmptyState />
      );
    },
  ),
);
