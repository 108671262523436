import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text } from 'wix-ui-tpa/Text';
import {
  ActiveFeatures,
  BusinessInfo,
  CatalogServiceDto,
} from '@wix/bookings-uou-types/dist/src';
import styles from './Contact.st.css';
import { AlignmentOptions, SectionTypes } from '../../types';
import Section from '../Section';

interface ContactComponentProps
  extends InjectedExperimentsProps,
    InjectedTranslateProps {
  businessEmailVisibility: boolean;
  businessPhoneNumberVisibility: boolean;
  businessAddressVisibility: boolean;
  contactDetailsTitleText: string;
  config: {
    serviceDto: CatalogServiceDto;
    businessInfo: BusinessInfo;
    activeFeatures: ActiveFeatures;
  };
}

export default translate()(
  withExperiments<ContactComponentProps>(
    ({
      t,
      businessEmailVisibility,
      businessPhoneNumberVisibility,
      businessAddressVisibility,
      contactDetailsTitleText,
      config,
    }) => {
      const { businessInfo } = config;

      const shouldAlignSectionToStart = () => {
        return (
          [
            businessEmailVisibility,
            businessPhoneNumberVisibility,
            businessAddressVisibility,
          ].filter((value) => value).length === 1
        );
      };

      const appendContent = shouldAlignSectionToStart()
        ? AlignmentOptions.LEFT
        : AlignmentOptions.CENTER;

      const contactSectionTitle = () => {
        return contactDetailsTitleText ? (
          <div>
            <Text
              className={styles.contactTitle}
              data-hook="contact-title"
              tagName="h2"
            >
              {t(contactDetailsTitleText)}
            </Text>
          </div>
        ) : null;
      };

      const businessPhoneNumber = () => {
        return businessPhoneNumberVisibility && businessInfo.phone ? (
          <div>
            <Text
              className={styles.contactItems}
              data-hook="business-phone-number"
            >
              {businessInfo.phone}
            </Text>
          </div>
        ) : null;
      };

      const businessEmail = () => {
        return businessEmailVisibility && businessInfo.email ? (
          <div>
            <Text className={styles.contactItems} data-hook="business-email">
              {businessInfo.email}
            </Text>
          </div>
        ) : null;
      };

      const businessAddress = () => {
        return businessAddressVisibility && businessInfo.address ? (
          <div>
            <Text className={styles.contactItems} data-hook="business-address">
              {businessInfo.address}
            </Text>
          </div>
        ) : null;
      };

      return businessInfo ? (
        <Section sectionType={SectionTypes.CONTACT}>
          <div
            {...styles('root', { alignItems: appendContent })}
            data-hook="contact-details-wrapper"
          >
            {contactSectionTitle()}
            {businessPhoneNumber()}
            {businessEmail()}
            {businessAddress()}
          </div>
        </Section>
      ) : null;
    },
  ),
);
