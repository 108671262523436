import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './TitleAndTagline.st.css';
import { AlignmentOptions, SectionTypes } from '../../types';
import Section from '../Section';
import { TitleAndTaglineViewModel } from '../../../../service-page-view-model/servicePageViewModel';

interface TitleAndTaglineProps
  extends InjectedExperimentsProps,
    InjectedTranslateProps {
  titleAndTaglineAlignment: AlignmentOptions;
  serviceTitleVisibility: boolean;
  serviceTaglineVisibility: boolean;
  viewModel: TitleAndTaglineViewModel;
}

export default translate()(
  withExperiments<TitleAndTaglineProps>(
    ({
      t,
      experiments,
      titleAndTaglineAlignment,
      serviceTaglineVisibility,
      serviceTitleVisibility,
      viewModel,
      ...rest
    }) => {
      const serviceTitle = () =>
        serviceTitleVisibility ? (
          <Text
            data-hook="title-and-tagline-title"
            className={styles.title}
            typography={TYPOGRAPHY.largeTitle}
            tagName="h2"
          >
            {viewModel.title}
          </Text>
        ) : null;

      const serviceTagline = () =>
        serviceTaglineVisibility ? (
          <Text
            data-hook="title-and-tagline-tagline"
            className={styles.tagline}
            typography={TYPOGRAPHY.runningText}
          >
            {viewModel.tagline}
          </Text>
        ) : null;

      return (
        <Section sectionType={SectionTypes.TITLE_TAGLINE}>
          <div
            {...styles('root', { alignment: titleAndTaglineAlignment }, rest)}
            data-hook="title-and-tagline-wrapper"
          >
            {serviceTitle()}
            {serviceTagline()}
          </div>
        </Section>
      );
    },
  ),
);
