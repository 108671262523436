import React from 'react';
import { translate } from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './EmptyState.st.css';

export default translate()(({ t, dataHook = 'empty-state' }) => {
  return (
    <div className={styles.root} data-hook={dataHook}>
      <Text
        data-hook="empty-state-title"
        className={styles.title}
        tagName="h2"
        typography={TYPOGRAPHY.largeTitle}
      >
        {t('empty-state.title')}
      </Text>
      <Text data-hook="empty-state-text" className={styles.text}>
        {t('empty-state.text')}
      </Text>
    </div>
  );
});
