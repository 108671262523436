import React from 'react';
import { SectionTypes, AlignmentOptions } from '../../types';
import BookButton from '../BookButton/BookButton';
import styles from './Section.st.css';
import { Divider } from 'wix-ui-tpa/Divider';

interface SectionProps {
  withBookButton?: boolean;
  sectionType: SectionTypes;
  buttonAlignment?: AlignmentOptions;
}

const Section: React.FC<SectionProps> = ({
  children,
  sectionType,
  withBookButton,
  buttonAlignment = AlignmentOptions.CENTER,
}) => {
  return (
    <>
      <div className={styles.root}>
        {sectionType !== SectionTypes.DETAILS && (
          <Divider
            data-hook={`divider-${sectionType}`}
            className={styles.divider}
          />
        )}
        {children}
        {withBookButton && (
          <div {...styles('buttonWrapper', { alignment: buttonAlignment })}>
            <BookButton dataHook={`book-button-${sectionType}`} />
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
